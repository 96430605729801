import React, { useEffect, useState } from 'react';
import { showNotification } from 'services/api';
import { getAccessToken } from 'services/auth';
import { logErrorCtx } from 'app/logging';
import {
  BackgroundStatus,
  fetchBackgroundStatus,
  fetchCSVStatuses,
  fetchAllCSVStatuses,
} from 'services/backgroundTasks';
import { getLocalStatuses, resetLocalStatuses } from './services';

import { BackgroundItem, BackgroundType } from './types';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'helpers/useFlags';

const INTERVAL = 2000;

export interface BackgroundTaskContextShape {
  backgroundTasks: BackgroundItem[];
  csvBackgroundTasks: BackgroundItem[];
  startFetching(): void;
  startCsvFetching(): void;
  lastQboTaskValue(): number | null;
}

export const BackgroundTaskContext =
  React.createContext<BackgroundTaskContextShape>({
    backgroundTasks: [],
    csvBackgroundTasks: [],
    startFetching: () => {},
    startCsvFetching: () => {},
    lastQboTaskValue: () => 0,
  });

export const BackgroundTaskProvider: React.FC<{
  children: React.ReactNode[];
}> = ({ children }) => {
  const [fetching, setFetching] = useState(true);
  const [csvFetched, setCsvFetched] = useState(false);
  const [csvFetching, setCsvFetching] = useState(false);
  const [backgroundTasks, setBackgroundTasks] = useState<BackgroundItem[]>([]);
  const [csvBackgroundTasks, setCsvBackgroundTasks] = useState<
    BackgroundItem[]
  >([]);
  const { getAccessTokenSilently } = useAuth0();
  const flags = useFlags();

  const isLoggedIn = flags.driveAuth0Enabled
    ? getAccessTokenSilently()
    : getAccessToken();

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    resetLocalStatuses();
    (async () => {
      const csvAllStatuses = await fetchAllCSVStatuses();
      setCsvBackgroundTasks(csvAllStatuses);
    })();
  }, []);

  useEffect(() => {
    const localStore = getLocalStatuses();
    if (!csvFetching && !localStore) {
      if (csvFetched) {
        showNotification('Your file has been successfully uploaded', {
          variant: 'success',
        });
        setCsvFetched(false);
      }
      return;
    }

    const interval = setInterval(() => {
      (async () => {
        if (isLoggedIn) {
          try {
            const csvStatuses = await fetchCSVStatuses();
            const needFetching = !csvStatuses.every(
              (s: { status: BackgroundStatus }) =>
                s.status === BackgroundStatus.Completed ||
                s.status === BackgroundStatus.Failed
            );
            setCsvFetching(needFetching);
            setCsvFetched(false);
            if (!needFetching) {
              const csvAllStatuses = await fetchAllCSVStatuses();
              setCsvBackgroundTasks(csvAllStatuses);
              clearInterval(interval);
            }
          } catch (err) {
            setCsvFetching(false);
            setCsvFetched(false);
          }
        } else {
          clearInterval(interval);
        }
      })();
    }, INTERVAL);
    return () => clearInterval(interval);
  }, [csvFetching, isLoggedIn]);

  useEffect(() => {
    if (!fetching || !isLoggedIn) {
      return;
    }

    const interval = setInterval(() => {
      (async () => {
        try {
          const statuses = await fetchBackgroundStatus();
          const needFetching = !statuses.every(
            (s) =>
              s.status === BackgroundStatus.Completed ||
              s.status === BackgroundStatus.Failed
          );
          setBackgroundTasks(statuses);
          setFetching(needFetching);
        } catch (err) {
          setFetching(false);
        }
      })();
    }, INTERVAL);
    return () => clearInterval(interval);
  }, [fetching, isLoggedIn]);

  const startCsvFetching = async () => {
    setCsvFetched(false);
    setCsvFetching(true);
    try {
      const statuses = await fetchCSVStatuses();
      setCsvBackgroundTasks(statuses);
    } catch (err) {
      const error = err as Error;
      logErrorCtx('Fetching CSV Statuses Failed (V2 endpoints)', {
        error,
        stackTrace: error.stack,
        title: 'Error Acquiring CSV Statuses from V2 endpoints',
        description:
          "Background task provider can't acquire imported CSV file statuses",
        component: 'BackgroundTaskProvider',
      });
    }
  };

  const startFetching = async () => {
    try {
      const statuses = await fetchBackgroundStatus();
      setBackgroundTasks(statuses);
    } catch (err) {
      const error = err as Error;
      logErrorCtx('Fetching Statuses Failed (V1 endpoints)', {
        error,
        stackTrace: error.stack,
        title: 'Error Acquiring Statuses from V1 endpoints',
        description:
          "Background task provider can't acquire imported CSV file statuses",
        component: 'BackgroundTaskProvider',
      });
    }
    setFetching(true);
  };

  const lastQboTaskValue = () => {
    const qboTasks = backgroundTasks.filter(
      (task: { type: BackgroundType }) => task.type === BackgroundType.Qbo
    );
    const qboLastTask = qboTasks.slice(-1)[0];
    const lastProgressValue = qboLastTask ? qboLastTask.progress : 0;
    return lastProgressValue;
  };

  return (
    <BackgroundTaskContext.Provider
      value={{
        backgroundTasks,
        csvBackgroundTasks,
        startFetching,
        startCsvFetching,
        lastQboTaskValue,
      }}
    >
      {children}
    </BackgroundTaskContext.Provider>
  );
};
