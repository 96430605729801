import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Channel, fetchTaxRates, getTaxRates } from 'services/taxRates';
import { Modal } from 'ui/components/Modal/Modal';
import {
  ChannelTaxRate,
  routes,
  TaxMappingRates,
  TaxRateMappingModalProps,
} from './types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  fetchXeroTaxRates,
  getXeroAccountIsConnected,
  getXeroTaxRates,
  InternationalTaxRate,
} from 'services/integrations/xero';
import {
  fetchQuickbookTaxRates,
  getQuickbooksAccountIsConnected,
  getQuickbooksTaxRates,
} from 'services/integrations/quickbooks';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { updateTaxRates } from './api';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';

const TaxRateMappingModal: React.FC<TaxRateMappingModalProps> = (props) => {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const { items: taxes } = useSelector(getTaxRates);
  const xeroConnected = useSelector(getXeroAccountIsConnected);
  const qboConnected = useSelector(getQuickbooksAccountIsConnected);
  const [accountingType, setAccountingType] = useState(Channel.None);
  const [contentLoading, setContentLoading] = useState(false);

  const [mappedAccounts, setMappedAccounts] = useState<TaxMappingRates[]>([]);
  const oldState = useRef<TaxMappingRates[] | null>([]);

  useEffect(() => {
    if (xeroConnected) setAccountingType(Channel.Xero);
    if (qboConnected) setAccountingType(Channel.Qbo);
  }, [xeroConnected, qboConnected]);

  const saveMappedTaxRates = useCallback(async () => {
    setContentLoading(true);
    oldState.current = mappedAccounts;

    try {
      await updateTaxRates(mappedAccounts, accountingType.toLocaleLowerCase());
    } catch {
      // continue regardless of error
    }
    // @ts-ignore
    dispatch(fetchTaxRates());
    setContentLoading(false);
    onClose();
    return true;
  }, [mappedAccounts, onClose]);

  const loadInternationalTaxRates = () => {
    accountingType === 'XERO'
      ? // @ts-ignore
        dispatch(fetchXeroTaxRates())
      : // @ts-ignore
        dispatch(fetchQuickbookTaxRates());
  };

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchTaxRates());
    loadInternationalTaxRates();
  }, []);

  const externalTaxes = useSelector(
    accountingType === 'XERO' ? getXeroTaxRates : getQuickbooksTaxRates
  );
  const ModalActions = () => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box mr={2}>
          <FBOButton
            data-qa="create-new-tax"
            variant="secondary"
            color="positive"
            size="medium"
            href={routes.AddNewTaxRates}
          >
            Create New Tax In Drive
          </FBOButton>
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <FBOButton
              onClick={onClose}
              variant="secondary"
              color="positive"
              size="medium"
              data-qa="tax-mapping-cancel"
            >
              Cancel
            </FBOButton>
          </Box>
          <NetworkSpinnerWrapper isLoading={contentLoading} size={24}>
            <FBOButton
              variant="primary"
              color="positive"
              size="medium"
              onClick={saveMappedTaxRates}
              data-qa="tax-mapping-save"
            >
              Save
            </FBOButton>
          </NetworkSpinnerWrapper>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (taxes.length > 0) {
      const tempMappedAccounts = taxes.map((t) => {
        const externalAccount =
          externalTaxes.length > 0 &&
          externalTaxes?.find(
            (et) =>
              et.name === t.mappedTaxName && et.taxType === t.channelTaxType
          );
        return {
          taxId: t.id,
          channelsTaxRate: externalAccount || null,
        } as unknown as TaxMappingRates;
      });

      tempMappedAccounts.sort((a, b) => a.taxId - b.taxId);

      setMappedAccounts(tempMappedAccounts);
    }
  }, [taxes, externalTaxes]);

  const handleExternalTaxRateChange = useCallback(
    (index: number) => (e: any, value: InternationalTaxRate | null) => {
      if (index || index === 0) {
        const tempAccounts = [...mappedAccounts];
        tempAccounts[index].channelsTaxRate = value as ChannelTaxRate;
        setMappedAccounts([...tempAccounts]);
      }
    },
    [setMappedAccounts, mappedAccounts]
  );

  return (
    <Modal
      open={open}
      onCancelClicked={onClose}
      title="Tax Mapping"
      ModalActionsComponent={ModalActions}
      withoutDefaultPadding
      isLoading={contentLoading}
      isLoadingContent={contentLoading}
      customHeight={700}
      maxWidth="md"
      footerDivider="shadow"
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>FBO Tax Rates</TableCell>
            <TableCell></TableCell>
            <TableCell>
              {xeroConnected ? 'Xero Tax Rates' : 'QuickBooks Tax Rates'}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mappedAccounts.length > 0 ? (
            mappedAccounts.map((account, index) => {
              const selectedExternalAccount =
                (externalTaxes.length > 0 &&
                  externalTaxes?.find(
                    (et) =>
                      et.name === account?.channelsTaxRate?.name &&
                      et.taxType === account?.channelsTaxRate?.taxType
                  )) ||
                null;
              const taxName =
                taxes.find((t) => t.id === account.taxId)?.name || '';
              return (
                <TableRow key={account.taxId}>
                  <TableCell>{taxName}</TableCell>
                  <TableCell style={{ width: '30px' }}>
                    <NavigateNextIcon />
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      options={externalTaxes}
                      value={selectedExternalAccount}
                      getOptionLabel={(account: InternationalTaxRate) =>
                        `${account?.name} (${account?.taxType})`
                      }
                      onChange={handleExternalTaxRateChange(index)}
                      placeholder="Select"
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <Typography
              color="textSecondary"
              component="div"
              display="flex"
              marginTop="40%"
              marginLeft="30%"
            >
              ADD NEW TAX RATE BY PRESSING 'CREATE NEW TAX IN DRIVE'
            </Typography>
          )}
        </TableBody>
      </Table>
    </Modal>
  );
};

export default memo(TaxRateMappingModal);
