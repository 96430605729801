import React from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';

import { Routes as AppRoutes } from 'app';

import { Routes } from '../../navigation';
import { AuthContainer } from '../../components';
import { LoginForm } from './components/LoginForm';
import { RouteComponentProps } from 'react-router';
import { useFlags } from 'helpers/useFlags';

interface LoginPageCmp extends React.FunctionComponent<LoginPageProps> {
  route: string;
}
interface LoginPageProps extends RouteComponentProps {}

const LoginPage: LoginPageCmp = (props: LoginPageProps) => {
  const {
    location: { search },
  } = props;

  const flags = useFlags();
  const [redirect, setRedirect] = React.useState(false);

  const { forwardUrl } = queryString.parse(search);
  const forwardUrlResolved = forwardUrl || AppRoutes.AppHome;

  const redirectToHomePage = () => {
    setRedirect(true);
  };

  if (redirect || flags.driveAuth0Enabled) {
    return <Redirect to={forwardUrlResolved as string} />;
  }

  return (
    <AuthContainer title="Welcome Back! Login" showNotACustomer={true}>
      <LoginForm afterLogin={redirectToHomePage} />
    </AuthContainer>
  );
};

LoginPage.route = Routes.LoginPage;

export default LoginPage;
