import moment from 'moment';
import _ from 'lodash';

import { countDecimals, transformDateToMomentDate } from 'helpers';
import { transformCustomer } from 'services/customers';
import { transformRepresentative } from 'services/representatives';
import { transformSaleItem } from 'services/items/saleItems';
import { showNumberAsPercentage, transformTaxRate } from 'services/taxRates';
import { transformLocation } from 'services/locations';
import {
  ObjectType,
  transformCustomField,
  transformToRequestCustomField,
} from 'services/customFields';
import { transformPaymentTerm } from 'services/paymentTerms';
import { transformItem } from 'services/items';
import { transformCurrency } from 'services/currencies';
import {
  transformDocument,
  transformToRequestDocument,
} from 'services/documents';

import {
  DiscountTypes,
  DisplaySalesOrder,
  SalesOrder,
  SalesOrderBundleItem,
  SalesOrderItem,
  SalesOrderItemTypes,
  SalesOrderPayment,
} from './types';
import { bundleItemIsCreditReturn } from './helpers';

export const transformDisplaySalesOrder = (
  response: any
): DisplaySalesOrder => {
  return {
    id: response.id,
    number: response.number,
    salesOrderStatus: response.salesOrderStatus,
    salesOrderType: response.salesOrderType,
    stockStatus: response.stockStatus,
    customerId: response.customerId,
    customer: response.customer,
    representativeId: response.representativeId,
    representative: response.representative,
    locationId: response.locationId,
    location: response.location,
    source: response.source,
    dateCreated: transformDateToMomentDate(response.created),
    dateLastModified: transformDateToMomentDate(response.modified),
    dateIssued: transformDateToMomentDate(response.issued),
    total: response.total,
    paymentTotal: response.paymentTotal,
    deleted: response.deleted,
  };
};

export const transformSalesOrder = (response: any): SalesOrder => {
  const salesOrderItemList = response.salesOrderItems
    ? response.salesOrderItems.map(transformSalesOrderItem)
    : [];
  const salesOrderBundleItems = response.salesOrderBundleItems
    ? response.salesOrderBundleItems.map(transformSalesOrderBundleItems)
    : [];
  const sortedSalesOrderItemList = _.sortBy(
    [...salesOrderItemList, ...salesOrderBundleItems],
    ['lineNumber']
  );
  const picks = response.picks || [];
  const shipments = response.shipments || [];
  const customFields = response.customFields || [];
  const payments = response.payments || [];
  const documents = response.documents || [];

  return {
    id: response.id,
    accountingClassId: response.accountingClassId,
    billToCity: response.billToCity,
    billToCountry: response.billToCountry,
    billToName: response.billToName,
    billToState: response.billToState,
    billToStreet: response.billToStreet,
    billToStreet2: response.billToStreet2,
    billToPostalCode: response.billToPostalCode,
    billToResidential: response.billToResidential,
    billToCompanyName: response.billToCompanyName,
    billToAttention: response.billToAttention,
    billToVerified: response.billToVerified,
    billToPhone: response.billToPhone, // @beta: additionally added for testing
    billToEmail: response.billToEmail, // @beta: additionally added for testing
    channelSource: response.channelSource,
    carrierId: response.carrierId,
    carrierServiceId: response.carrierServiceId,
    customerId: response.customerId,
    customer: response.customer ? transformCustomer(response.customer) : null,
    customerPONumber: response.customerPONumber,
    customFields: customFields.map(transformCustomField),
    dateCreated: transformDateToMomentDate(response.dateCreated),
    dateExpires: transformDateToMomentDate(response.dateExpires),
    dateFulfilled: transformDateToMomentDate(response.dateFulfilled),
    dateIssued: transformDateToMomentDate(response.dateIssued),
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    dateDue: transformDateToMomentDate(response.dateDue),
    dateScheduled: response.dateScheduled
      ? moment(response.dateScheduled).format('YYYY-MM-DD')
      : null,
    documents: documents.map(transformDocument),
    dropShipPurchaseOrders: response.dropShipPurchaseOrders,
    emails: response.emails,
    emailStatus: response.emailStatus,
    exchangeCurrency: response.exchangeCurrency
      ? transformCurrency(response.exchangeCurrency)
      : null,
    exchangeCurrencyId: response.exchangeCurrencyId,
    homeCurrency: response.homeCurrency
      ? transformCurrency(response.homeCurrency)
      : null,
    homeCurrencyId: response.homeCurrencyId,
    exchangeRate: response.exchangeRate,
    multiCurrencyDiscountTotal: response.multiCurrencyDiscountTotal,
    multiCurrencyGrandTotal: response.multiCurrencyGrandTotal,
    multiCurrencyOrderLevelDiscountTotal:
      response.multiCurrencyOrderLevelDiscountTotal,
    multiCurrencyPaymentTotal: response.multiCurrencyPaymentTotal,
    multiCurrencySubtotal: response.multiCurrencySubtotal,
    multiCurrencyTaxTotal: response.multiCurrencyTaxTotal,
    fobPointId: response.fobPointId,
    grandTotal: response.grandTotal,
    locationId: response.locationId,
    location: response.location ? transformLocation(response.location) : null,
    margin: response.margin,
    marginPercent: response.marginPercent,
    markupPercent: response.markupPercent,
    markup: response.markup,
    notes: response.notes,
    number: response.number,
    orderLevelDiscountFlatAmount: response.orderLevelDiscountFlatAmount,
    orderLevelDiscountPercent: response.orderLevelDiscountPercent
      ? showNumberAsPercentage(response.orderLevelDiscountPercent)
      : response.orderLevelDiscountPercent,
    paymentTerm: response.paymentTerm
      ? transformPaymentTerm(response.paymentTerm)
      : null,
    paymentTermId: response.paymentTermId,
    paymentTotal: response.paymentTotal,
    payments: payments.map(transformToRequestSalesOrderPayments),
    phone: response.phone,
    priorityId: response.priorityId,
    printStatus: response.printStatus,
    picks,
    representativeId: response.representativeId,
    representative: response.representative
      ? transformRepresentative(response.representative)
      : null,
    salesOrderItemList,
    salesItems: sortedSalesOrderItemList,
    salesTax: response.salesTax ? transformTaxRate(response.salesTax) : null,
    salesTaxId: response.salesTaxId,
    status: response.salesOrderStatus,
    shipToResidential: response.shipToResidential,
    shippingTermId: response.shippingTermId,
    shipToCity: response.shipToCity,
    shipToCountry: response.shipToCountry,
    shipToName: response.shipToName,
    shipToState: response.shipToState,
    shipToStreet: response.shipToStreet,
    shipToStreet2: response.shipToStreet2,
    shipToPostalCode: response.shipToPostalCode,
    shipToCompanyName: response.shipToCompanyName,
    shipToAttention: response.shipToAttention,
    shipToVerified: response.shipToVerified,
    shipToPhone: response.shipToPhone, // @beta: additionally added for testing
    shipToEmail: response.shipToEmail, // @beta: additionally added for testing
    shipments,
    salesOrderBundleItems,
    stockStatus: response.stockStatus,
    store: response.store,
    receipt: response.receipt,
    vendorPONumber: response.vendorPONumber,
    version: response.version,
    deleted: response.deleted,
  };
};

export const transformSalesOrderItem = (item: any): SalesOrderItem => {
  let saleItem = null;
  if (item.saleItem) {
    saleItem = transformSaleItem(item.saleItem);
  }

  const discount = {
    value: item.discountPercent
      ? item.discountPercent * 100
      : item.discountFlatAmount
      ? item.discountFlatAmount
      : 0,
    type: item.discountPercent ? DiscountTypes.Percent : DiscountTypes.FlatRate,
  };

  const multiCurrencyItemPrice =
    item.price && item.price * (item.exchangeRate || 1);

  return {
    id: item.id,
    cost: item.cost,
    deleted: false,
    description: item.description,
    discount,
    discountTotal: item.discountTotal,
    exchangeCurrency: item.exchangeCurrency
      ? transformCurrency(item.exchangeCurrency)
      : null,
    exchangeCurrencyId: item.exchangeCurrencyId,
    exchangeRate: item.exchangeRate,
    multiCurrencyDiscountTotal: item.multiCurrencyDiscountTotal,
    multiCurrencyTaxTotal: item.multiCurrencyTaxTotal || 0,
    multiCurrencyTotal: item.multiCurrencyTotal,
    multiCurrencyItemPrice,
    multiCurrencySubTotal:
      (item.multiCurrencyTotal || 0) - (item.multiCurrencyTaxTotal || 0),
    hasPricingRule: false,
    lineNumber: item.lineNumber,
    name: item.name,
    notes: item.notes,
    isFiveDecimals: item.price
      ? countDecimals(parseFloat(item.price)) > 2
      : false,
    price: item.price || 0,
    quantity: item.quantity || 0,
    quantityFulfilled: item.quantityFulfilled,
    saleItem,
    salesOrder: item.salesOrder ? transformSalesOrder(item.salesOrder) : null,
    saleItemId: item.saleItemId,
    salesOrderId: item.salesOrderId,
    salesOrderItemType: item.salesOrderItemType,
    status: item.salesOrderItemStatus,
    subTotal: item.total
      ? item.total - (item.taxTotal || 0)
      : item.price * (item.quantity || 0),
    tax: item.tax ? transformTaxRate(item.tax) : null,
    taxable: item.taxable,
    taxId: item.taxId,
    taxRate: item.taxRate,
    taxTotal: item.taxTotal || 0,
    total: item.total,
    uomId: item.uomId,
    version: item.version,
    countyTaxAmount: item.countyTaxAmount || 0,
    countyTaxRate: item.countyTaxRate || 0,
    stateTaxAmount: item.stateTaxAmount || 0,
    stateTaxRate: item.stateSalesTaxRate || 0,
    cityTaxAmount: item.cityTaxAmount || 0,
    cityTaxRate: item.cityTaxRate || 0,
    specialDistrictTaxAmount: item.specialDistrictTaxAmount || 0,
    specialDistrictTaxRate: item.specialTaxRate || 0,
  };
};

export const transformSalesOrderBundleItems = (
  res: any
): SalesOrderBundleItem => {
  const item = res.item
    ? transformItem({ ...res.item, description: res.description })
    : null;
  const sortedSalesOrderItemList = _.sortBy(
    res.salesOrderItems.map(transformSalesOrderItem),
    ['lineNumber']
  );

  const taxesSum = sortedSalesOrderItemList.reduce(
    (a: SalesOrderItem, b: SalesOrderItem) => {
      return {
        countyTaxAmount: a.countyTaxAmount + b.countyTaxAmount,
        countyTaxRate: a.countyTaxRate + b.countyTaxRate,
        stateTaxAmount: a.stateTaxAmount + b.stateTaxAmount,
        stateTaxRate: a.stateTaxRate + b.stateTaxRate,
        cityTaxAmount: a.cityTaxAmount + b.cityTaxAmount,
        cityTaxRate: a.cityTaxRate + b.cityTaxRate,
        specialDistrictTaxAmount:
          a.specialDistrictTaxAmount + b.specialDistrictTaxAmount,
        specialDistrictTaxRate:
          a.specialDistrictTaxRate + b.specialDistrictTaxRate,
      };
    }
  );

  const { uomId = item?.defaultUomId ?? null, ...transforms } =
    transformSalesOrderItem(res);

  return {
    ...transforms,
    uomId,
    name: item ? item.name : null,
    description: item ? item.description : null,
    itemId: res.itemId,
    status: res.salesOrderBundleItemStatus,
    item,
    salesOrderItemType: bundleItemIsCreditReturn(res)
      ? SalesOrderItemTypes.BundleCreditReturn
      : SalesOrderItemTypes.Bundle,
    salesOrderItems: sortedSalesOrderItemList,
    countyTaxAmount: taxesSum.countyTaxAmount,
    countyTaxRate: taxesSum.countyTaxRate,
    stateTaxAmount: taxesSum.stateTaxAmount,
    stateTaxRate: taxesSum.stateTaxRate,
    cityTaxAmount: taxesSum.cityTaxAmount,
    cityTaxRate: taxesSum.cityTaxRate,
    specialDistrictTaxAmount: taxesSum.specialDistrictTaxAmount,
    specialDistrictTaxRate: taxesSum.specialDistrictTaxRate,
  };
};

export const transformRequestSalesOrder = (salesOrder: SalesOrder): any => {
  const dateScheduledUTC = moment(
    salesOrder.dateScheduled || moment().format('YYYY-MM-DD'),
    'YYYY-MM-DD'
  );

  const salesOrderItems: SalesOrderItem[] = [];
  const salesOrderBundleItems: SalesOrderBundleItem[] = [];

  salesOrder.salesItems.forEach((si) => {
    if (
      si.salesOrderItemType === SalesOrderItemTypes.Bundle ||
      si.salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn
    ) {
      salesOrderBundleItems.push(transformToRequestSalesOrderItem(si));
      return;
    }

    salesOrderItems.push(transformToRequestSalesOrderItem(si));
  });

  const newSalesOrder = {
    accountingClassId: salesOrder.accountingClassId,
    amountsIncludeTax: false,
    billToCity: salesOrder.billToCity,
    billToPostalCode: salesOrder.billToPostalCode,
    billToCountry: salesOrder.billToCountry,
    billToName: salesOrder.billToName,
    billToState: salesOrder.billToState,
    billToStreet: salesOrder.billToStreet,
    billToStreet2: salesOrder.billToStreet2,
    billToResidential: salesOrder.billToResidential,
    billToCompanyName: salesOrder.billToCompanyName,
    billToAttention: salesOrder.billToAttention,
    billToVerified: salesOrder.billToVerified,
    billToPhone: salesOrder.billToPhone, // @beta: additionally added for testing
    billToEmail: salesOrder.billToEmail, // @beta: additionally added for testing
    carrierId: salesOrder.carrierId,
    carrierServiceId: salesOrder.carrierServiceId,
    customerId: salesOrder.customerId,
    customerPONumber: salesOrder.customerPONumber,
    customFields: salesOrder.customFields.map((c) =>
      transformToRequestCustomField(c, ObjectType.SalesOrder, true)
    ),
    dateExpires: salesOrder.dateExpires
      ? moment(salesOrder.dateExpires, 'YYYY-MM-DD').toISOString()
      : null,
    dateScheduled: dateScheduledUTC,
    deleted: salesOrder.deleted,
    documents: salesOrder.documents.map(transformToRequestDocument),
    emails: salesOrder.emails,
    emailStatus: salesOrder.emailStatus,
    exchangeCurrencyId: salesOrder.exchangeCurrencyId,
    exchangeRate: salesOrder.exchangeRate,
    fobPointId: salesOrder.fobPointId,
    locationId: salesOrder.locationId,
    notes: salesOrder.notes,
    number: salesOrder.number,
    orderLevelDiscountFlatAmount: salesOrder.orderLevelDiscountFlatAmount,
    orderLevelDiscountPercent: salesOrder.orderLevelDiscountPercent
      ? salesOrder.orderLevelDiscountPercent / 100
      : salesOrder.orderLevelDiscountPercent,
    paymentTermId: salesOrder.paymentTermId,
    phone: salesOrder.phone,
    printStatus: salesOrder.printStatus,
    priorityId: salesOrder.priorityId,
    representativeId: salesOrder.representativeId,
    shipToResidential: salesOrder.shipToResidential,
    salesTaxId: salesOrder.salesTaxId,
    shippingTermId: salesOrder.shippingTermId,
    shipToCity: salesOrder.shipToCity,
    shipToPostalCode: salesOrder.shipToPostalCode,
    shipToCountry: salesOrder.shipToCountry,
    shipToName: salesOrder.shipToName,
    shipToState: salesOrder.shipToState,
    shipToStreet: salesOrder.shipToStreet,
    shipToStreet2: salesOrder.shipToStreet2,
    shipToCompanyName: salesOrder.shipToCompanyName,
    shipToAttention: salesOrder.shipToAttention,
    shipToVerified: salesOrder.shipToVerified,
    shipToPhone: salesOrder.shipToPhone, // @beta: additionally added for testing
    shipToEmail: salesOrder.shipToEmail, // @beta: additionally added for testing
    vendorPONumber: salesOrder.vendorPONumber,
    salesOrderItems,
    salesOrderBundleItems,
  };

  // POST
  if (salesOrder.id && salesOrder.id < 0) {
    return newSalesOrder;
  }

  // PUT
  return {
    ...newSalesOrder,
    id: salesOrder.id,
    version: salesOrder.version,
  };
};

const transformToRequestSalesOrderItem = (
  soItem: SalesOrderItem | SalesOrderBundleItem
): any => {
  switch (soItem.salesOrderItemType) {
    case SalesOrderItemTypes.Sale:
    case SalesOrderItemTypes.CreditReturn:
    case SalesOrderItemTypes.DropShip:
      return transformToRequestSaleOrCreditReturn(soItem);
    case SalesOrderItemTypes.MiscSale:
    case SalesOrderItemTypes.MiscReturn:
      return transformToRequestMiscSaleOrCreditReturn(soItem);
    case SalesOrderItemTypes.FlatTaxRate:
      return transformToRequestFlatTaxRate(soItem);
    case SalesOrderItemTypes.Note:
      return transformToRequestNote(soItem);
    case SalesOrderItemTypes.Bundle:
    case SalesOrderItemTypes.BundleCreditReturn:
      return transformToRequestBundleItem(soItem);
  }
};

// Sale item, drop ship and credit return
const transformToRequestSaleOrCreditReturn = (soItem: SalesOrderItem): any => {
  const discountPercent =
    soItem.discount.type === DiscountTypes.Percent
      ? (soItem.discount.value || 0) / 100
      : null;

  const discountFlatAmount =
    soItem.discount.type === DiscountTypes.FlatRate
      ? soItem.discount.value
      : null;

  const request: any = {
    cost: soItem.cost,
    description: soItem.description,
    discountFlatAmount,
    discountPercent,
    lineNumber: soItem.lineNumber,
    price: soItem.price,
    quantity: soItem.quantity,
    notes: soItem.notes,
    saleItemId: soItem.saleItemId,
    salesOrderItemType: soItem.salesOrderItemType,
    taxable: soItem.taxable,
    taxId: soItem.taxId,
    taxRate: soItem.taxRate,
    uomId: soItem.uomId,
    exchangeCurrencyId: soItem.exchangeCurrencyId,
    exchangeRate: soItem.exchangeRate,
  };

  // POST
  if (soItem.id === null || soItem.id < 0) {
    return request;
  }

  // PUT
  return {
    ...request,
    id: soItem.id,
    deleted: soItem.deleted,
    version: soItem.version,
  };
};

const transformToRequestBundleItem = (
  soItem: SalesOrderBundleItem | any
): any => {
  const discountPercent =
    soItem.discount.type === DiscountTypes.Percent
      ? (soItem.discount.value || 0) / 100
      : null;

  const discountFlatAmount =
    soItem.discount.type === DiscountTypes.FlatRate
      ? soItem.discount.value
      : null;

  const request: any = {
    name: soItem.name,
    cost: soItem.cost,
    itemId: soItem.itemId,
    description: soItem.description,
    discountFlatAmount,
    discountPercent,
    lineNumber: soItem.lineNumber,
    price: soItem.price,
    quantity: soItem.quantity,
    notes: soItem.notes,
    saleItemId: soItem.saleItemId,
    salesOrderItemType: soItem.salesOrderItemType,
    taxable: soItem.taxable,
    taxId: soItem.taxId,
    taxRate: soItem.taxRate,
    uomId: soItem.uomId,
    exchangeCurrencyId: soItem.exchangeCurrencyId,
    exchangeRate: soItem.exchangeRate,
    salesOrderItems: soItem.salesOrderItems.map((soi: SalesOrderItem) => ({
      ...transformToRequestSaleOrCreditReturn(soi),
      name: soi.name,
    })),
  };

  if (soItem.id === null || soItem.id < 0) {
    //POST AS CREDIT RETURN
    if (soItem.salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn) {
      return {
        ...request,
        salesOrderItemType: SalesOrderItemTypes.CreditReturn,
      };
    }
    //POST
    return request;
  }

  const putRequest = {
    ...request,
    id: soItem.id,
    deleted: soItem.deleted,
    version: soItem.version,
  };

  //PUT AS CREDIT RETURN
  if (soItem.salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn) {
    return {
      ...putRequest,
      salesOrderItemType: SalesOrderItemTypes.CreditReturn,
    };
  }
  //PUT
  return putRequest;
};

// Misc item and misc credit return
const transformToRequestMiscSaleOrCreditReturn = (
  soItem: SalesOrderItem
): any => {
  const discountPercent =
    soItem.discount.type === DiscountTypes.Percent
      ? (soItem.discount.value || 0) / 100
      : null;

  const discountFlatAmount =
    soItem.discount.type === DiscountTypes.FlatRate
      ? soItem.discount.value
      : null;

  const request: any = {
    discountFlatAmount,
    discountPercent,
    lineNumber: soItem.lineNumber,
    name: soItem.name,
    notes: soItem.notes,
    cost: soItem.cost,
    price: soItem.price,
    quantity: soItem.quantity,
    salesOrderItemType: soItem.salesOrderItemType,
    taxable: soItem.taxable,
    taxId: soItem.taxId,
    taxRate: soItem.taxRate,
    uomId: soItem.uomId,
    exchangeCurrencyId: soItem.exchangeCurrencyId,
    exchangeRate: soItem.exchangeRate,
  };

  // POST
  if (soItem.id === null || soItem.id < 0) {
    return request;
  }

  // PUT
  return {
    ...request,
    id: soItem.id,
    deleted: soItem.deleted,
    version: soItem.version,
  };
};

// Flat tax rate
const transformToRequestFlatTaxRate = (soItem: SalesOrderItem): any => {
  const request: any = {
    lineNumber: soItem.lineNumber,
    price: soItem.price,
    notes: soItem.notes,
    quantity: 1, // quantity must be 1
    salesOrderItemType: soItem.salesOrderItemType,
    taxable: false,
    taxId: soItem.taxId,
    exchangeCurrencyId: soItem.exchangeCurrencyId,
    exchangeRate: soItem.exchangeRate,
  };

  // POST
  if (soItem.id === null || soItem.id < 0) {
    return request;
  }

  // PUT
  return {
    ...request,
    id: soItem.id,
    deleted: soItem.deleted,
    version: soItem.version,
  };
};

// Note
const transformToRequestNote = (soItem: SalesOrderItem): any => {
  const request: any = {
    lineNumber: soItem.lineNumber,
    name: soItem.name,
    notes: soItem.notes,
    salesOrderItemType: soItem.salesOrderItemType,
    taxable: false,
    exchangeRate: soItem.exchangeRate,
  };

  // POST
  if (soItem.id === null || soItem.id < 0) {
    return request;
  }

  // PUT
  return {
    ...request,
    id: soItem.id,
    deleted: soItem.deleted,
    version: soItem.version,
  };
};

// Payments
export const transformToRequestSalesOrderPayments = (
  res: any
): SalesOrderPayment => {
  const data = res.data ? JSON.parse(res.data) : {};

  return {
    id: res.id,
    amount: res.amount,
    checkVersion: res.checkVersion,
    createdBy: res.createdBy,
    createdById: res.createdById,
    creditCardType: res.creditCardType,
    customer: res.customer,
    customerId: res.customerId,
    data,
    date: res.date,
    dateCreated: res.dateCreated,
    dateLastModified: res.dateLastModified,
    expirationMonth: res.expirationMonth,
    expirationYear: res.expirationYear,
    lastFour: res.lastFour,
    lastModifiedBy: res.lastModifiedBy,
    lastModifiedById: res.lastModifiedById,
    multiCurrencyAmount: res.multiCurrencyAmount,
    nameOnCard: res.nameOnCard,
    originalPaymentId: res.originalPaymentId,
    paymentTypeId: res.paymentTypeId,
    paymentType: res.paymentType,
    referenceNumber: res.referenceNumber,
    salesOrderId: res.salesOrderId,
    status: res.status,
    transactionId: res.transactionId,
    transactionType: res.transactionType,
    version: res.version,
    refunded: res.refunded,
    remainingForRefund: res.remainingForRefund,
  };
};
