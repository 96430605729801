import { defaultMaximumPagination, RequestConfig } from 'helpers';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';

import {
  transformUser,
  transformRequestUser,
  transformCrmAssociation,
} from './transformations';
import { CrmAssociation, User } from './types';

export const fetchUsersAPI = async (
  config: RequestConfig
): Promise<DataWithPagination<User>> => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = ['firstName', 'lastName'],
    quickSearchValue,
  } = config;

  let path = `/v1/users${expands.join(',')}`;

  if (quickSearchValue) {
    path += `?quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  const res = await paginatedApiCall(path, pagination, transformUser);
  return res;
};

export const fetchUserById = async (id: number): Promise<User> => {
  const response = await createApiCall({
    path: `/v1/users/${id}`,
    method: 'GET',
  })();

  return transformUser(response.data);
};

export const createUser = async (user: User): Promise<User> => {
  const response = await createApiCall(
    {
      path: `/v1/users`,
      method: 'POST',
      body: transformRequestUser(user),
    },
    {
      getMessage: {
        success: () => 'New user successfully added',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformUser(response.data);
};

export const logInAsUser = async (
  userToLoginAsEmail: string,
  secret: string
): Promise<any> => {
  const response = await createApiCall(
    {
      path: `/v1/admin/login_as`,
      method: 'POST',
      body: { userToLoginAsEmail, secret },
    },
    {
      getMessage: {
        success: () => 'Logged In Successfuly',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return response.data.remoteAccessToken;
};

export const connectToUserOrganization = async (
  adminEmail: string | undefined,
  userToLoginAsEmail: string,
  secret: string
): Promise<{ organizationId: string }> => {
  const response = await createApiCall(
    {
      path: `/v1/admin/connect_user_organization`,
      method: 'POST',
      body: { adminEmail, userToLoginAsEmail, secret },
    },
    {
      getMessage: {
        success: () => 'Connected successfully!',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return response.data;
};

export const disconnectFromUserOrganization = async (
  adminEmail: string | undefined,
  orgId?: string
): Promise<string | undefined> => {
  const response = await createApiCall(
    {
      path: `/v1/admin/disconnect_user_organization`,
      method: 'POST',
      body: { adminEmail, orgId },
    },
    {
      getMessage: {
        success: () => 'Disconnected successfully!',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
  return response.data;
};

export const updateUser = async (user: User): Promise<User> => {
  const response = await createApiCall(
    {
      path: `/v1/users`,
      method: 'PUT',
      body: transformRequestUser(user),
    },
    {
      getMessage: {
        success: () => 'User successfully updated',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformUser(response.data);
};

export const deleteUser = async (userId: number): Promise<any> => {
  const response = await createApiCall(
    {
      path: `/v1/users/${userId}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'User successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return response.data;
};

export const updateCompany = async (
  maxUsers: number,
  expirationDate: Date,
  companyId: number,
  crmId: string,
  tenantAccountType: string
): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/admin/company/${companyId}`,
      method: 'PUT',
      body: { maxUsers, expirationDate, crmId, tenantAccountType },
    },
    {
      getMessage: {
        success: () => 'Company updated',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};
export const fetchCrmAssociationsById = async (
  companyId: number
): Promise<CrmAssociation> => {
  const response = await createApiCall({
    path: `/admin/v2/companies/${companyId}/crm-associations`,
    method: 'GET',
  })();
  return transformCrmAssociation(response.data.results[0]);
};
