import React, { memo } from 'react';
import { Pagination } from 'services/search';
import { IconNames } from 'ui/theme';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { ChannelTableProps } from './types';
import { PageCustomRow } from '../PageCustomRow';
import { CHANNEL_COLUMNS } from '../../consts';
import { openInNewTab } from 'helpers';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { useChannelTableStyle } from './styled';

const ChannelTable: React.FC<ChannelTableProps> = (props) => {
  const {
    items,
    activeItemId,
    handleItemClick,
    handleOpenSettingsModal,
    isLoadingItems,
    pagination,
    onPaginationChange,
  } = props;

  const style = useChannelTableStyle();

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const resolvedColumns = activeItemId
    ? CHANNEL_COLUMNS.filter((c) => c.title === 'Vendor' || c.title === 'Name')
    : CHANNEL_COLUMNS;

  return (
    <div>
      <FBOTitleBar title="My Channels">
        {!activeItemId && (
          <div className={style.buttonsContainer}>
            <FBOButton
              variant="secondary"
              color="neutral"
              size="medium"
              icon={IconNames.MenuSettings}
              onClick={handleOpenSettingsModal}
              data-qa="channels-settings"
            >
              Global Settings
            </FBOButton>
            <FBOButton
              variant="primary"
              color="positive"
              size="medium"
              icon={IconNames.FBOLeavePage}
              onClick={() =>
                openInNewTab(`${process.env.REACT_APP_SELLWARE_URL}/channels`)
              }
              data-qa="manage-channels-in-sellware"
            >
              Manage Channels In Fishbowl Commerce
            </FBOButton>
          </div>
        )}
      </FBOTitleBar>

      <ItemsTable
        data={items}
        columns={resolvedColumns}
        activeId={activeItemId}
        RenderCustomRow={PageCustomRow}
        onItemClick={handleItemClick}
        selectableItems={false}
        isLoadingData={isLoadingItems}
        meta={{ activeItemId }}
        dataQa="channels-table"
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        emptyTableText={
          'Set up your first channel to get started with Fishbowl Commerce'
        }
        hideFooter
      />
    </div>
  );
};
export default memo(ChannelTable);
