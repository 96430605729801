/* eslint-disable */
import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Routes } from 'ui/modules/remotesupport/navigation/routes';
import {
  clearAdminAccessToken,
  getAdminAccessToken,
  getRemoteOrganizationData,
} from 'services/auth/services';

import FBOButton from '../FBOButton/FBOButton';
import { useFlags } from 'helpers/useFlags';
import { useAuth0 } from '@auth0/auth0-react';
import { disconnectFromUserOrganization } from 'services/user';

export type FBOAppBarCmp = React.FunctionComponent<FBOAppBarComponentProps>;

export interface FBOAppBarComponentProps {}

const FBOAppBar: FBOAppBarCmp = () => {
  const history = useHistory();
  const flags = useFlags();
  const { loginWithRedirect, logout, user } = useAuth0();

  const hasAdminAccess = useMemo(
    () =>
      flags?.driveAuth0Enabled
        ? user?.adminSupportAccess
        : !!getAdminAccessToken(),
    []
  );

  const logOutRemoteAccessLegacy = useCallback(() => {
    clearAdminAccessToken();
    history.push(Routes.RemoteSupportPage);
    history.go(0);
  }, [history]);

  const logOutRemoteAccessSSO = useCallback(async () => {
    const remoteOrganizationData = getRemoteOrganizationData();

    try {
      const firstRemainingOrganizationId = await disconnectFromUserOrganization(
        user?.email,
        user?.org_id
      );
      clearAdminAccessToken();
      if(firstRemainingOrganizationId) {
        await loginWithRedirect({authorizationParams: {organizationId: firstRemainingOrganizationId}});
      } else {
        await logout({
          logoutParams: { returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL },
        });
      }
    } catch {
      // ignore error
    }
  }, [history]);

  const logOutRemoteAccess = useCallback(async () => {
    if (flags?.driveAuth0Enabled) {
      await logOutRemoteAccessSSO();
      return;
    }

    logOutRemoteAccessLegacy();
  }, [flags?.driveAuth0Enabled]);

  return (
    <Box data-qa="fbo-app-bar">
      {hasAdminAccess ? (
        <FBOButton
          variant="primary"
          color="negative"
          size="large"
          onClick={logOutRemoteAccess}
          data-qa="remote-user-log-out"
          fullWidth
          style={{ marginBottom: '16px' }}
        >
          Log Out of Remote User
        </FBOButton>
      ) : null}
    </Box>
  );
};

export default FBOAppBar;
